<script lang="ts">
    import { check_lg } from "@timephy/tui-icons-svelte"
    import { Icon } from "svelte-awesome"

    /* ========================================================================================== */

    export let value: boolean

    /* ========================================================================================== */

    function onClick() {
        value = !value
    }
</script>

<button
    on:click|preventDefault={onClick}
    class="btn {value ? 'btn-blue' : 'btn-gray'}
    flex h-5 w-5 shrink-0 items-center justify-center rounded-md"
    type="button"
>
    {#if value}
        <Icon data={check_lg}></Icon>
    {/if}
</button>
