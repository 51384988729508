<script lang="ts">
    // ====================================================================================================

    export let value: string
    export let placeholder: string = ""

    export let prefix: string | null = null
    export let rounded: string = "12px"

    export let disabled: boolean = false
    export let invalid: boolean = false

    export let name: string = ""
    export let type = "text"
    export let autocomplete: string = ""

    let focus = false
</script>

<div
    style:border-radius={rounded}
    class="flex h-8 overflow-hidden border border-step-150 bg-step-100
    {invalid ? 'outline outline-1 outline-red-000' : ''}
    {focus ? 'outline !outline-2 outline-blue-000' : ''}
    {disabled ? 'pointer-events-none opacity-50' : ''}
    "
>
    {#if prefix}
        <div class="flex items-center justify-center pl-2">
            <p class="text-step-500">{prefix}</p>
        </div>
    {/if}
    <input
        bind:value
        {placeholder}
        class="min-w-0 grow bg-[transparent] placeholder-step-500 outline-none
        {prefix ? 'pl-1 pr-2' : 'px-2'}"
        on:focus={() => (focus = true)}
        on:blur={() => (focus = false)}
        {...{ type }}
        {autocomplete}
        {name}
        {disabled}
        aria-invalid={invalid}
    />

    <slot name="right" />
</div>
