<script lang="ts">
    import { eye_fill, eye_slash_fill } from "@timephy/tui-icons-svelte"
    // ====================================================================================================

    import Input from "./Input.svelte"
    import InputButton from "./InputButton.svelte"

    export let value: string
    export let placeholder: string = ""
    export let invalid: boolean = false

    export let name: string = ""
    export let autocomplete: string = ""

    let showPassword = false
</script>

<Input
    bind:value
    type={showPassword ? "text" : "password"}
    {name}
    {placeholder}
    {invalid}
    {autocomplete}
>
    <InputButton
        slot="right"
        color="gray"
        icon={showPassword ? eye_fill : eye_slash_fill}
        on:click={(e) => {
            showPassword = !showPassword
            e.preventDefault()
        }}
    />
</Input>
