<script lang="ts">
    import type { IconType } from "svelte-awesome/components/Icon.svelte"
    import Button from "../Button.svelte"
    import type { Color } from "../Button.svelte"

    // ====================================================================================================

    export let color: Color = "blue"
    export let icon: IconType | null = null
    export let type: "button" | "submit" | "reset" = "button"
</script>

<Button
    thin
    {type}
    {color}
    {icon}
    class="-mr-[1px] -mt-[1px] !rounded-l-none !px-3"
    tabindex={-1}
    on:click
>
    <slot />
</Button>
